<template>
  <div class="container">
    <el-swiper :src='imgSrc'
               :info="info"></el-swiper>
    <div class="container_ container1">
      <div class="main">
        <ul class="part1_ul">
          <li class="part1_li">
            <div class="icon_"></div>
            <p>基于移动互联网+新能源汽车，以打造智慧城市交通网为目的的共享自驾出行服务平台</p>
          </li>
          <li class="part1_li">
            <div class="icon_"></div>
            <p>采用共享模式和分享经济，实现全民共享用车，任意点取还车，大大减少市民出行成本，方便城市生活</p>
          </li>
          <li class="part1_li">
            <div class="icon_"></div>
            <p>以汽车分时租赁作为引流入口，从后服务门店经营、个体投资盈利、车辆保值增值等方面提供更多效益</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="container_ container2">
      <img class="img_"
           src="../assets/images/hxj_part2.png"
           alt="">
    </div>
    <div class="container_ container3">
      <div class="main">
        <div class="part part3">
          <div class="title">用车管理 一手掌握</div>
          <div class="bgimg"></div>
          <div class="innerTitle">企业用车管理中心</div>
          <ul class="part3_ul">
            <li class="part3_li">
              <img class="img_"
                   src="../assets/images/hxj_part3_1.png"
                   alt="">
              <p>在线申请</p>
            </li>
            <li class="part3_li">
              <img class="img_"
                   src="../assets/images/hxj_part3_2.png"
                   alt="">
              <p>出入记录</p>
            </li>
            <li class="part3_li">
              <img class="img_"
                   src="../assets/images/hxj_part3_3.png"
                   alt="">
              <p>人员管理</p>
            </li>
            <li class="part3_li">
              <img class="img_"
                   src="../assets/images/hxj_part3_4.png"
                   alt="">
              <p>行程监管</p>
            </li>
            <li class="part3_li">
              <img class="img_"
                   src="../assets/images/hxj_part3_5.png"
                   alt="">
              <p>维护报修</p>
            </li>
            <li class="part3_li">
              <img class="img_"
                   src="../assets/images/hxj_part3_6.png"
                   alt="">
              <p>成本控制</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgSrc: require('@/assets/images/banner8.png'),
      info: {
        title: '尚盈车联·好享驾',
        dsc: '共享出行服务平台',
        number: ''
      }
    };
  },
  created () {

  },
  mounted () {

  },
  methods: {

  },
}
</script>

<style lang='scss' scoped>
@import "@/assets/styles/hxj.scss";
</style>